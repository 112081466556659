import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";

import {
  trainingNameHandler,
  BUY_BUTTON_TITLES,
  freezeNameHandler,
} from "./languageConstants";

import { INFO1_SVG } from "../../../../../constants/svgClubConstants";
import { DROP_SVG } from "../../../../../constants/svgConstants";

import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Link from "next/link";
import { GET_CLUB_HEADER_DATA } from "../../../../../graphql/queries/queriesClub";
import { ChooseProfileSection } from "../TrainingSection/TrainingPage/ChooseProfileSection";
import { useAuth } from "../../../../../utils/auth";
import { useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { setAuthorization } from "../../../../../utils/redux/actionCreators/actions";

function SubscriptionCardMobile({
  title,
  subtitle,
  price,
  amount = 0,
  pauses = 0,
  duration,

  status,
  group,
  isGroup,
  id,
  cardId,
  // buttonFunc,
  // CustomButton,
  // customButtonProps,

  // cardOpenPriceEnabled,
}) {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { data: { club: { currencyTitle } } = {} } = useQuery(
    GET_CLUB_HEADER_DATA,
    { variables: { showOffer: false } }
  );

  const router = useRouter();
  const {
    query: { club },
  } = router;

  const { isAuthorized } = useAuth();

  const toDetail = useCallback(
    (familyProfileId) => {
      const pathname = `/club/${club}/subscription/${id}`;
      if (!isAuthorized)
        return dispatch(setAuthorization({ nextUrl: pathname }));

      return router.push(
        {
          pathname,
          query: { familyProfileId },
        },
        pathname
      );
    },
    [id, isAuthorized]
  );

  const { trainingTitle, buttonTitle, freezeName } = useMemo(() => {
    const trainingTitle = trainingNameHandler(amount),
      buttonTitle = BUY_BUTTON_TITLES[status],
      freezeName = freezeNameHandler(pauses);

    return {
      trainingTitle,
      buttonTitle,
      freezeName,
    };
  }, [amount, pauses, status]);

  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

  return (
    (<Link
      href={`/club/${club}/subscription/${cardId ?? id}`}
      className="subscription-card-mobile"
      onClick={(e) => {
        if (
          e.target.nodeName === "BUTTON" ||
          !e.currentTarget.contains(e.target)
        )
          e.preventDefault();
      }}>

      <div
        className="subscription-card-mobile__header"
        onClick={(e) => {
          e.preventDefault();
          setShowAdditionalInfo(!showAdditionalInfo);
        }}
      >
        <div
          className={`subscription-card-mobile__status subscription-card-mobile__${status}`}
        ></div>
        <div
          className={
            showAdditionalInfo
              ? "subscription-card-mobile__info-button " +
                "subscription-card-mobile__info-button_additional"
              : "subscription-card-mobile__info-button"
          }
        >
          {showAdditionalInfo ? DROP_SVG : INFO1_SVG}
        </div>

        <div className="subscription-card-mobile__content">
          <h3 className="subscription-card-mobile__title">{title}</h3>
          <p className="subscription-card-mobile__subtitle">{subtitle}</p>
        </div>
      </div>
      {/*  additional content */}
      <div
        className="subscription-card-mobile__additional-info"
        style={showAdditionalInfo ? { display: "flex" } : { display: "none" }}
      >
        <hr />

        {isGroup && (
          <div
            className="subscription-card-content-block"
            style={{ marginTop: "10px" }}
          >
            <p className="subscription-card-content-title">{group}</p>
          </div>
        )}

        <div className="subscription-card-content-block">
          <p className="subscription-card-content-subtitle">
            {t("duration")}
          </p>
          <p
            className={`subscription-card-content-title ${
              duration ? "" : "subscription-card-content-title__hidden"
            }`}
          >
            {duration} {t("days")}
          </p>
        </div>

        <div className="subscription-card-content-block">
          <p className="subscription-card-content-subtitle">{t("amount")}</p>
          <p className="subscription-card-content-title">
            {amount === -1 ? t("unlimited") : `${amount} ${t(trainingTitle)}`}
          </p>
        </div>
        {!!pauses && (
          <div className="subscription-card-content-block">
            <p className="subscription-card-content-subtitle">
              {t("number_pauses")}
            </p>

            <p
              className={`subscription-card-content-title ${
                pauses ? "" : "subscription-card-content-title__hidden"
              }`}
            >
              {pauses} {t(freezeName)}
            </p>
          </div>
        )}

        <hr />

        {/* {cardOpenPriceEnabled && ( */}
        <div className="subscription-card-price">
          {price} {currencyTitle}
        </div>
        {/* )} */}

        <div className="subscription-card-button-wrapper">
          <ChooseProfileSection onClick={toDetail}>
            {(onClick) => (
              <button
                className={`subscription-card-button subscription-card-button_${status}`}
                onClick={onClick}
              >
                {!!buttonTitle && t(buttonTitle)}
              </button>
            )}
          </ChooseProfileSection>
          {/* {CustomButton ? (
            <CustomButton
              {...customButtonProps}
              status={status}
              buttonTitle={buttonTitle}
              cardId={id}
            />
          ) : (
            <div
              className={`subscription-card-button subscription-card-button_${status}`}
            >
              <p className="subscription-card-button__span">{buttonTitle}</p>
            </div>
          )} */}
        </div>
      </div>

    </Link>)
  );
}

SubscriptionCardMobile.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  price: PropTypes.number,
  amount: PropTypes.number,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isStatus: PropTypes.bool,
  status: PropTypes.string,

  group: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isGroup: PropTypes.bool,
  matchUrl: PropTypes.string,
  buttonFunc: PropTypes.func,
  activeClub: PropTypes.string,
  customButtonProps: PropTypes.object,
  isSubscription: PropTypes.bool,
};

SubscriptionCardMobile.defaultProps = {
  title: "",
  subtitle: "",
  price: null,
  amount: null,
  duration: null,
  isStatus: true,
  status: "buy",
  group: "",
  isGroup: false,
  matchUrl: "",
  buttonFunc: null,
  CustomButton: null,
  activeClub: "",
  customButtonProps: {},
  isSubscription: false,
};

// status=default||activated||notActivated||expired

export default SubscriptionCardMobile;
